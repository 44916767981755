import siteConfig from "./config"

// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.scss"

// katex support
import "katex/dist/katex.min.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

// font
import "typeface-barlow"
import "@fontsource/crimson-text";
import "@fontsource/abril-fatface";

function initGTM() {
  if (window.isGTMLoaded) {
    return false
  }

  window.isGTMLoaded = true

  const script = document.createElement("script")

  script.type = "text/javascript"
  script.async = true
  script.src = `https://www.googletagmanager.com/gtag/js?id=${siteConfig.googleAnalyticsId}`

  script.onload = () => {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag("js", new Date())

    gtag("config", siteConfig.googleAnalyticsId)
  }

  document.head.appendChild(script)
}

function loadGTM(event) {
  initGTM()
  event.currentTarget.removeEventListener(event.type, loadGTM)
}

function loadTwitterStyles() {
  // Add twitter icons
  const script = document.createElement("script")
  script.async = true
  script.src = "https://platform.twitter.com/widgets.js"
  script.charset = "utf-8"
  document.head.appendChild(script)
}

export const onClientEntry = () => {
  // loadTwitterStyles()
  if (process.env.NODE_ENV === "production") {
    document.onreadystatechange = () => {
      if (document.readyState !== "loading") {
        setTimeout(initGTM, 3500)
      }
    }

    document.addEventListener("scroll", loadGTM)
    document.addEventListener("mousemove", loadGTM)
    document.addEventListener("touchstart", loadGTM)
  }
}
