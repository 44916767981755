module.exports = {
  url: `https://carmencincotti.com`,
  description: `Computer Graphics and Physics Simulation Tutorials and Articles`,
  pathPrefix: "/",
  title: `Carmen's Graphics Blog`,
  disqusShortname: "",
  postsPerPage: 15,
  googleAnalyticsId: "G-C9LKEYHYM6",
  useKatex: false,
  author: {
    name: `Carmen Cincotti`,
    summary: `, computer graphics enthusiast, language learner, and improv actor currently living in San Francisco, CA`,
    social: {
      github: `ccincotti3`,
      email: `cincotti@tuta.io`,
      linkedin: `carmencincotti`,
      medium: `@carmencincotti`,
      instagram: "carmen_cincotti_",
      twitter: "@CarmenCincotti"
    },
  },
}
